html {
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  user-select: none;
}

::selection,
::-moz-selection {
  background: #01bf71;
  color: #010606; 
}
